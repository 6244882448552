import React, { Component } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { postData, reFreashReactTable, css, AjaxConfirm, toastMessageShow, getApplicantId, getMemberId, getLoginToken } from 'service/common.js';
import { connect } from 'react-redux'
import { defaultSpaceInTable } from 'service/custom_value_data.js';
import { ROUTER_PATH } from 'config.js';
import PropTypes from 'prop-types';
import moment from "moment";
import jQuery from 'jquery';
import SLDSReactTable from '../salesforce/lightning/SLDSReactTable'
import '../scss/components/admin/member/member.scss'
import MemberShifts from './MemberShifts';
import {
    IconSettings,
    PageHeader,
    PageHeaderControl,
    Icon,
    ButtonGroup,
    Button,
    Dropdown,
    DropdownTrigger,
    Card,
    Input,
    InputIcon
} from '@salesforce/design-system-react';
import {showStatusChangeConfirm, openAddEditShiftModal, updateShiftStatus} from './MemberCommon';
import SystemAlertPopup from './SystemAlertPopup';
/**
 * RequestData get the list of shifts available or accepted
 */
const requestData = (type, pageSize, page, sorted, filtered) => {
    return new Promise((resolve, reject) => {
        // request json
        var Request = { type: type, applicant_id: getApplicantId(), member_id: getMemberId(), pageSize: pageSize, page: page, sorted: sorted, filtered: filtered };
        postData('recruitment/Recruitment_member/get_shifts_member', Request).then((result) => {
            if (result.status) {
                let filteredData = result.data;
                const res = {
                    rows: filteredData,
                    pages: (result.count)
                };
                resolve(res);
            } else {
                const res = {
                    rows: [],
                    pages: 0
                };
                resolve(res);
            }
        });
    });
};

/**
 * Class: AvailableShiftsList
 */
class AvailableShiftsList extends Component {

    /**
     * default displayed columns in the listing
     */
    static defaultProps = {
        displayed_columns: {
            "id": true,
            "shift_no": true,
            "account_fullname": true,
            "scheduled_start_datetime": true,
            "scheduled_end_datetime": true,
            "scheduled_duration": true,
            "status_label": true,
            "role_name": true,
            "actions": true,
            "address": true
        }
    }

    /**
     * setting the initial prop values
     * @param {*} props
     */
    constructor(props) {
        super(props);

        var displayed_columns = Object.keys(props.displayed_columns);

        // Initialize state
        this.state = {
            applicant_id: getApplicantId(),
            member_id: getMemberId(),
            searchVal: '',
            filterVal: '',
            shifts_list: [],
            filter_status: 'all',
            openCreateModal: false,
            shift_id: '',
            default_displayed_columns: displayed_columns,
            displayed_columns: [...displayed_columns],     
            showAlert:false,
            alertMessage:''      
        }
        // Initilize react table
        this.reactTable = React.createRef();
        this.rootRef = React.createRef()
    }

    /**
     * Call the requestData
     * @param {temp} state
     */
    fetchData = (state, instance) => {
        this.setState({ loading: true });
        requestData(
            this.props.type,
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            this.setState({
                shifts_list: res.rows,
                pages: res.pages,
                loading: false
            });
        });
    }

    /**
     * Get the list based on Search value
     * @param {object} e
     */
    submitSearch = (e) => {
        e.preventDefault();
        this.setTableParams();
    }

    /**
     * Set the data for fetching the list
     */
    setTableParams = () => {
        var search_re = { search: this.state.search, filter_status: this.state.filter_status };
        this.setState({ filtered: search_re });
        if(this.props.isMobile) {
            this.fetchData({pageSize: 9999, page: 0, sorted: '', filtered: search_re});
        }
    }

    /**
     * fetching the member details by the applicant id
     */
    get_applicant_member_details_by_id = () => {
        let req = {};
        if(this.state.applicant_id && this.state.applicant_id!='0'){
            req = {applicant_id: this.state.applicant_id}
        }else{
            req = {member_id: this.state.member_id}
        } 
        postData('recruitment/Recruitment_member/get_applicant_member_details_by_id', req).then((result) => {
            if (result.status) {
                var id = result.data.id;
                this.setState(result.data);
            }
        });
    }

    /*
     * method runs after the component output has been rendered to the DOM
     */
    componentDidMount() {
        jQuery(this.rootRef.current).parent('.col-lg-11').removeClass('col-lg-11').addClass('col-lg-12')
        this.get_applicant_member_details_by_id();
        if(this.props.isMobile) {
            this.fetchData({pageSize: 9999, page: 0, sorted: '', filtered: ''});
        }
    }

    componentWillUnmount() {
        jQuery(this.rootRef.current).parent('.col-lg-12').removeClass('col-lg-12').addClass('col-lg-11')
    }

    /**
     * Open create shift modal
     */
    showModal(shift_id, modal_name, status) {
        this.setState({ openCreateModal: true, shift_id: shift_id, modal_name: modal_name , shift_status : status});
    }

    /**
     * Close the modal when user save the shift and refresh the table
     */
    closeAddEditShiftModal = (status) => {
        this.setState({openCreateModal: false});

        if(status){
            this.setTableParams();
        }
    }

     /**
     * Close the modal when user save the shift and refresh the table
     */
      refreshpage = () => {
        this.setState({openCreateModal: false});
        reFreashReactTable(this, 'fetchData');

    }

    /**
     * Render search input form
     */
    renderSearchForm() {
        return (
            <form
                autoComplete="off"
                onSubmit={(e) => this.submitSearch(e)}
                method="post"
                className="slds-col_padded"
                style={{ display: 'block' }}
            >
                <Input
                    iconLeft={
                        <InputIcon
                            assistiveText={{
                                icon: 'Search',
                            }}
                            name="search"
                            category="utility"
                        />
                    }
                    onChange={(e) => this.setState({ search: e.target.value })}
                    id="Schedule-member-search-1"
                    placeholder={"Search " + this.props.type_cap + " Shifts"}
                />
            </form>
        )
    }

    /**
     * Handle the selected columns visible or not
     */
    handleOnSelectColumnSelectorItem = option => {
        const value = option.value

        let cols = [...this.state.displayed_columns]
        if (cols.indexOf(value) >= 0) {
            cols = cols.filter(col => col !== value)
        } else {
            cols = [...this.state.displayed_columns, value]
        }

        this.setState({ displayed_columns: cols })
    }

    /**
     * Render table column dropdown
     * @param {object} param
     * @param {(import('react-table').Column & { _label: string })[]} [param.columns]
     */
    renderColumnSelector({ columns = [] }) {
        columns = columns.filter(col => (col.accessor != "actions"));
        const mapColumnsToOptions = columns.map(col => {
            return ({
                value: 'id' in col ? col.id : col.accessor,
                label: col._label,
            })
        })

        return (
            <Dropdown
                align="right"
                checkmark
                multiple
                assistiveText={{ icon: 'More' }}
                iconCategory="utility"
                iconName="settings"
                iconVariant="more"
                options={mapColumnsToOptions}
                value={this.state.default_displayed_columns}
                onSelect={this.handleOnSelectColumnSelectorItem}
            >
                <DropdownTrigger>
                    <Button
                        title={`Show/hide columns`}
                        assistiveText={{ icon: 'Show/hide columns' }}
                        iconCategory="utility"
                        iconName="table"
                        iconVariant="more"
                        variant="icon"
                    />
                </DropdownTrigger>
            </Dropdown>
        )
    }

    /**
     * Render page header
     * Not displaying column selector in the mobile view
     * @param {object} param
     * @param {import('react-table').Column[]} [param.columns]
     */
    handleOnRenderControls = ({ columns = [] }) => () => {
        return (
            <React.Fragment>
                <PageHeaderControl>
                    { this.renderSearchForm() }
                </PageHeaderControl>
                {(this.props.isMobile) ? '' :
                <PageHeaderControl>
                    { this.renderColumnSelector({ columns }) }
                </PageHeaderControl>}
            </React.Fragment>
        )
    }


    /**
     * 
     * display  System Alert
     */
     displayAlert=(message='',status=null)=>{
       this.setState({showAlert:true,alertMessage:message,shiftStatus:status})
     }
    /**system alert close */

    closeAlert=()=>{
       this.setState({showAlert:false})
     }
   
    /**
     * Table columns
     * @returns {(import('react-table').Column & { _label: string })[]}
     */
    determineColumns() {
        return [
            {
                _label: 'Shift Number',
                accessor: "shift_no",
                id: 'shift_no',
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate"><Link to={ROUTER_PATH + `shift/` + props.original.shift_id} title={`View shift information`} style={{ color: '#0070d2' }}>{defaultSpaceInTable(props.value)}</Link></span>
            },
            {
                _label: 'Account',
                accessor: "account_fullname",
                id: 'account_fullname',
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate">{defaultSpaceInTable(props.value)}</span>
            },
            {
                _label: 'Address',
                accessor: "address",
                id: 'address',
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate">{defaultSpaceInTable(props.value)}</span>
            },
            {
                _label: 'Status',
                accessor: "status_label",
                id: 'status_label',
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate">{defaultSpaceInTable(props.value)}</span>
            },
            {
                _label: 'Work Type',
                accessor: "role_name",
                id: 'role_name',
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate">{defaultSpaceInTable(props.value)}</span>
            },
            {
                _label: 'Scheduled Start Time',
                accessor: "scheduled_start_datetime",
                id: 'scheduled_start_datetime',
                Header: ({ data, column }) => <div className="ellipsis_line1__">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate">{defaultSpaceInTable(moment(props.value).format("DD/MM/YYYY hh:mm A"))}</span>
            },
            {
                _label: 'Scheduled End Time',
                accessor: "scheduled_end_datetime",
                id: 'scheduled_end_datetime',
                Header: ({ data, column }) => <div className="ellipsis_line1__">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate">{defaultSpaceInTable(moment(props.value).format("DD/MM/YYYY hh:mm A"))}</span>
            },
            {
                _label: 'Duration (h)',
                accessor: "scheduled_duration",
                id: 'scheduled_duration',
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate">{defaultSpaceInTable(props.value)}</span>
            },
            {
                _label: 'Action',
                accessor: "actions",
                className: "slds-tbl-card-td slds-tbl-card-td-dd",
                Header: props => <div>&nbsp;</div>,
                Cell: props => <Dropdown
                    assistiveText={{ icon: 'More Options' }}
                    iconCategory="utility"
                    iconName="down"
                    align="right"
                    iconSize="x-small"
                    iconVariant="border-filled"
                    onSelect={(e) => {
                        if(this.props.type == "available" || this.props.type == "available_all") {
                            showStatusChangeConfirm(props.original.id, props.original.shift_id, props.original.shift_no, e.value, moment(props.original.scheduled_start_datetime).format("DD/MM/YYYY hh:mm A"), this.setTableParams())
                        }
                        else if(e.id == "timesheet" || e.id == "shift_notes") {
                            if(e.disabled == false) {
                                this.setState({selected_option_id:e.id,
                                    selected_shift_id:props.original.shift_id,
                                    selected_shift_no: props.original.shift_no},()=>{
                                        this.showModal(props.original.shift_id, e.id, props.original.status);
                                    })
                            }
                        }
                        else {
                            if(e.disabled == false && (e.id=='complete' || e.id=='start') ) {
                                updateShiftStatus(e.id, props.original.shift_id, props.original.shift_no, this.refreshpage,null,this.displayAlert);
                            }
                        }
                    }}

                    width="xx-small"

                    options={(this.props.type == "available" || this.props.type == "available_all") ? [
                        { id: '1', label: 'Accept', value: '1' },
                        { id: '2', label: 'Decline', value: '2' },
                    ] : [
                        { id: 'start', label: 'Start', value: '1', disabled: (props.original.status == 3) ? false : true },
                        { id: 'timesheet', label: 'Timesheet', value: '1', disabled: (props.original.status == 4) ? false : true },
                        { id: 'shift_notes', label: 'Shift Notes', value: '1', disabled: (props.original.status == 4) ? false : true },
                        { id: 'complete', label: 'Complete', value: '2', disabled: (props.original.status == 4) ? false : true },
                    ]}
                />
            }
        ]
    }

    /**
     * render header part
     */
    renderHeader() {
        const columns = this.determineColumns();
        const trail = [
            <Link to={ROUTER_PATH + `myshifts`} className="reset" style={{ color: '#0070d2' }}>
                {'My Shifts'}
            </Link>,
            <a href={ROUTER_PATH + `mydetails`} className="reset" style={{ color: '#0070d2' }} id="shift-details">{this.state.fullname}</a>,
        ];

        let title_txt = this.props.type_cap;
        if (this.props.type === 'completed') {
            title_txt = 'Completed';
        } else if (this.props.type === 'cancelled') {
            title_txt = 'Cancelled';
        }

        if(this.props.isMobile) {
            return (
                <PageHeader
                icon={
                    <Link to={ROUTER_PATH + `myshifts`} className="back_link" style={{ color: '#0070d2' }}>
                        <Button
                            assistiveText={{ icon: 'Icon Bare Small' }}
                            iconCategory="utility"
                            iconName="chevronleft"
                            iconSize="large"
                            iconVariant="bare"
                            onClick={() => {
                                console.log('Icon Bare Clicked');
                            }}
                            variant="icon"
                        />
                    </Link>
                }
                className="slds-header-document-mob-view"
                onRenderActions={this.handleOnRenderControls({ columns })}
                trail={[<span> </span>]}
                title={title_txt + " Shifts"}
                label={<span />}
                truncate
                variant="object-home"
            />
            )
        }
        else {
            return (
                <React.Fragment>
                <PageHeader
                    trail={trail}
                    // onRenderActions={this.handleOnRenderActions}
                    onRenderActions={this.handleOnRenderControls({ columns })}
                    title={title_txt + " Shifts"}
                    label=" "
                    truncate
                    variant="related-list"
                />
                </React.Fragment>
            )
        }
    }

    /**
     * Rendering the mobile listing part.
     */
    renderMobileList() {

        if(this.state.loading) {
            return <React.Fragment></React.Fragment>
        }

        if (this.state.shifts_list.length == 0 && this.props.isMobile) {
            return <div class="slds-notify slds-notify--alert slds-theme--info slds-theme--alert-texture slds-notify--mobile slds-align-left" role="alert">No shifts found!</div>
        }

        return (
            <React.Fragment>
                <div className="mt-2">
                <Card
                    hasNoHeader={true}
                    className="slds-card-bor"
                    >
                    <MemberShifts shifts={this.state.shifts_list} type={this.props.type + "_all"} refAvailableShifts={this.setTableParams}></MemberShifts>
                </Card>
                </div>
            </React.Fragment>
        )
    }

    /**
     * Render the display content
     */
    render() {
        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
            }
        })

        var columns = this.determineColumns();
        columns = columns.filter(col => (col.accessor != "id"));
        const displayedColumns = columns.filter(col => this.state.displayed_columns.indexOf(col.accessor || col.id) >= 0)

        // return
        return (
            <React.Fragment>
                <div className="slds col-lg-12 custom_page_header" style={styles.root} ref={this.rootRef}>
                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                        {this.renderHeader()}

                        {(this.props.isMobile) ? this.renderMobileList() : <SLDSReactTable
                            PaginationComponent={() => false}
                            ref={this.reactTable}
                            manual="true"
                            pages={this.state.pages}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            defaultFiltered={{ filter_status: 'all' }}
                            columns={displayedColumns}
                            data={this.state.shifts_list}
                            defaultPageSize={9999}
                            minRows={1}
                            getTableProps={() => ({ className: 'slds-table slds-table_cell-buffer slds-table_bordered slds-table_striped slds-tbl-card slds-tbl-scroll' })}
                            onPageSizeChange={this.onPageSizeChange}
                            noDataText="No records Found"
                            collapseOnDataChange={true}
                            resizable={true}
                        />}
                    </IconSettings>
                </div>
                {openAddEditShiftModal(this.state.shift_id, this.state.openCreateModal, this.closeAddEditShiftModal, this.state.modal_name, this.state.shift_status)}
                {this.state.showAlert&&(<SystemAlertPopup  
                                        shiftStatus={this.state.shiftStatus}
                                        closeAlert ={this.closeAlert}
                                        alertMessage={this.state.alertMessage}/>)}
            </React.Fragment>
        )
    }
}
// Defalut Prop
ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}

const mapStateToProps = state => ({})
const mapDispatchtoProps = (dispach) => {}

export default connect(mapStateToProps, mapDispatchtoProps)(AvailableShiftsList);
