import React from 'react';
import jQuery from 'jquery';
import _, { isLength, sum } from 'lodash'
import { connect } from 'react-redux';
import moment from 'moment'
import {
    IconSettings,
    PageHeaderControl,
    ButtonGroup,
    Button,
    Icon,
    PageHeader,
    Tabs,
    TabsPanel,
    Card,
    Popover,
} from '@salesforce/design-system-react'
import { ROUTER_PATH } from '../../../config.js';
import { postData, reFreashReactTable, css, AjaxConfirm, toastMessageShow, getApplicantId, getMemberId, getLoginToken, lockObject, unlockObject } from 'service/common.js';
import '../scss/components/admin/crm/pages/sales/opportunity/OpportunityDetails.scss'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { SplitContainer } from '../salesforce/lightning/DetailsComponent';
import { DetailsTitle } from '../salesforce/lightning/DetailsTitleCard';
import { showStatusChangeConfirm, openAddEditShiftModal, updateShiftStatus } from './MemberCommon';
import ScheduleStatusPath from './ScheduleStatusPath.jsx';
import ShiftAttachmentCard from './Attachment/ShiftAttachmentCard.jsx';

import ShiftNeedAssessmentCard from './ShiftTabCards/ShiftNeedAssessmentCard.jsx';
import ShiftRiskAssessmentCard from './ShiftTabCards/ShiftRiskAssessmentCard.jsx';

import ShiftPreviousShiftCard from './ShiftTabCards/ShiftPreviousShiftCard.jsx';
import ShiftGoalTracking from './ShiftTabCards/ShiftGoalTracking.jsx';

import AttachmentCard from './ShiftTabCards/NeedAssesmentDetails/AttachmentCard'
import { callMoreEvent } from '../../admin/oncallui-react-framework/services/common_filter';
import {getAddressForViewPage} from '../../admin/oncallui-react-framework/services/common';
import SystemAlertPopup from './SystemAlertPopup';
/**
 * Renders the shift details page
 */
class ShiftDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shift_id: _.get(this.props, 'props.match.params.id'),
            shift_member_id: '',
            accepted_shift: false,
            openCreateModal: false,
            loading: false,
            shift_no: '',
            owner_person: '',
            accept_reject_status: 0,
            notAvailable: 'N/A',
            redirectTo: null,
            showAttachment: false,
            currentTab: 0,
            showAssessment: false,
            isEvent: false,
            moreLoaded: false,
            isFirstRender: false,
            tab_length: 5, // total details tab length
            showAlert:false,
            alertMessage:''   
        }

        /**
         * @type {React.Ref<HTMLDivElement>}
         */
        this.rootRef = React.createRef();
        this.reactTable = React.createRef();
        this.attachmentRef = React.createRef();
    }

    /**
     * checking if a member is assigned to a shift
     */
    get_shift_member_details_frm_member_id = (shift_id) => {
        this.setState({ loading: true });
        var req = { id: shift_id, applicant_id: getApplicantId(), member_id: getMemberId() }
        postData('recruitment/Recruitment_member/get_shift_member_details_frm_member_id', req).then((result) => {
            if (result.status && result.data) {
                var accepted_shift = false;

                // accepted by someone else?
                if (result.data.accepted_shift_member_id > 0 && !result.data.accepted_shift) {
                    this.redirectToListing();
                }
                // is rejected by the member?
                if (result.data.status == 2) {
                    this.redirectToListing();
                }
                var changestate = { shift_member_id: result.data.id, accepted_shift: result.data.accepted_shift, accept_reject_status: result.data.status };
                var attachment_details = { attachment_id: result.data.attachment_id, aws_uploaded_flag: result.data.aws_uploaded_flag, file_path: result.data.file_path, file_show_url: result.data.file_show_url, file_type: result.data.file_type, filename: result.data.filename, file_size: result.data.file_size }
                this.setState(changestate);
                this.setState({ attachment_details: attachment_details, showAttachment: true }, () => {
                    if (attachment_details && this.state.status >= 3 && this.state.showAssessment) {
                        this.attachmentRef.refreshPage(attachment_details);
                    }
                });
            } else {
                this.redirectToListing();
            }
            this.setState({ loading: false, moreLoaded: true }, () => {
                
                
                setTimeout(() => {
                    callMoreEvent();
                }, 1000);
            })

        });
    }

    /**
     * fetching the shift details
     */
    get_shift_details = (shift_id) => {
        this.setState({ loading: true });
        var req = { id: shift_id, applicant_id: getApplicantId(), member_id: getMemberId() }
        postData('recruitment/Recruitment_member/get_shift_details', req).then((result) => {
            if (result.status) {
                this.setState({ ...result.data, checklist: result.checklist });
                if (result.data.account_person) {
                    result.data.account_person.account_type == 1 ? this.get_need_and_risk_details(result.data.contact_person.value, result.data.account_person.account_type) : this.get_need_and_risk_details(result.data.account_person.value, result.data.account_person.account_type);
                }
            } else {
                toastMessageShow(result.error, "e");
            }
            this.setState({ loading: false })
        });
    }

    /**
     * fetching the shift details
     */
    get_need_and_risk_details = (account_person_id, account_type) => {
        this.setState({ loading: true });
        var req = { account_person_id: account_person_id, account_type: account_type }
        postData('sales/NeedAssessment/get_need_and_risk_details', req).then((result) => {
            if (result.status) {
                this.setState({ need_assessment_id: result.data.need_assessment_id, risk_assessment_id: result.data.risk_assessment_id, showAssessment: true });
            } else {
                toastMessageShow(result.error, "e");
            }
            this.setState({ loading: false, showAssessment: true })
        });
    }

    /**
     * When component is mounted
     */
    componentWillMount() {
        const id = _.get(this.props, 'props.match.params.id');
        this.get_shift_details(id);
        this.get_shift_member_details_frm_member_id(id);

        // unlock object
        window.addEventListener('beforeunload', (ev) => {
            unlockObject(id ,'shift');
        })
        window.addEventListener('popstate', (event) => {
            if (event.state) {
                unlockObject(id ,'shift');
            }
        }, false);
    }

      
   
    /** Return with 1h 2m format */
    getAllowanceDuration(duration) {
        if (duration.indexOf(":") !== -1) {
            let scheDuration = duration.split(":");
            return scheDuration[0] + 'h ' + scheDuration[1] + 'm';
        }
    }
    /**
     * Action renderer for `<PageHeader />`
     */

    changeTab = (tabName) => {

        let id = document.getElementById('more_btn');

        if (id) {
            let liLength = document.getElementById("more_tab_len").getElementsByTagName("li").length;
            let sub = this.state.tab_length - liLength;
            let ans = sub + tabName;

            document.getElementById("more_tab_len").addEventListener('click', (event) => {
                if (event) {
                    this.setState({ isEvent: true }, () => { });
                }
            });
            if(!this.state.isFirstRender){
                this.setState({ currentTab: ans, isFirstRender: true, isEvent: false });
                return;
            }
           else if (!this.state.isEvent) {
                this.setState({ currentTab: tabName, isEvent: false });
                return;
            } else {
                this.setState({ currentTab: ans, isEvent: false });
                return;
            }
        } else {
            this.setState({ currentTab: tabName });
            return;
        }
    }

     /**
     * 
     * display  System Alert
     */
      displayAlert=(message='',status=null)=>{
        this.setState({showAlert:true,alertMessage:message,shiftStatus:status})
      }
     /**system alert close */
 
     closeAlert=()=>{
        this.setState({showAlert:false})
      }


    actions = () => {
        return (
            <PageHeaderControl>
                {this.state.accepted_shift ?
                    <ButtonGroup variant="list" id="button-group-page-header-actions" className="shiftbtns_list">
                        <Button label="Start" id="start" title={`Start the shift`} onClick={(e) => updateShiftStatus(e.target.id, this.state.id, this.state.shift_no, this.get_shift_details,null,this.displayAlert)} disabled={(this.state.status == 3) ? false : true} />
                        <Button label="Timesheet" id="timesheet" title={`Timesheet`} onClick={(e) => this.showModal('', 'timesheet')} disabled={(this.state.status == 4) ? false : true} />
                        <Button label="Shift Notes" id="shift_notes" title={`Shift Notes`} onClick={(e) => this.showModal('', 'shift_notes')} disabled={(this.state.status == 4) ? false : true} />
                        <Button label={this.state.status === '5' ? 'Completed' : 'Complete'} id="complete" title={`Complete the shift`} onClick={(e) => updateShiftStatus(e.target.id, this.state.id, this.state.shift_no, this.get_shift_details,null,this.displayAlert)} disabled={(this.state.status == 4) ? false : true} />

                    </ButtonGroup> :
                    <ButtonGroup variant="list" id="button-group-page-header-actions">
                        <Button label="Accept" title={`Accept the shift`} onClick={(e) => {
                            showStatusChangeConfirm(this.state.shift_member_id, this.state.id, this.state.shift_no, 1, moment(this.state.scheduled_start_datetime).format("DD/MM/YYYY hh:mm A"), this.get_shift_details, this.get_shift_member_details_frm_member_id)
                        }} />
                        <Button label="Decline" title={`Decline the shift`} onClick={(e) => {
                            showStatusChangeConfirm(this.state.shift_member_id, this.state.id, this.state.shift_no, 2, moment(this.state.scheduled_start_datetime).format("DD/MM/YYYY hh:mm A"), this.get_shift_details, this.get_shift_member_details_frm_member_id)
                        }} />
                    </ButtonGroup>
                }
            </PageHeaderControl>
        )
    }

    /**
     * Renders link for related account. Also displays a popoever on account link click
     */
    renderRelatedAccountLink() {
        const ACCOUNT_TYPE_CONTACT = 1
        const ACCOUNT_TYPE_ORGANISATION = 2
        const accountType = _.get(this.state, 'account_type', ACCOUNT_TYPE_CONTACT)
        const accountId = _.get(this.state, 'account_person.value')
        let tooltip = undefined;
        var popcontent_row1 = '';
        var popcontent_row2 = '';
        var person_org = _.get(this.state, 'account_person.label', null)
        if (!person_org) {
            return this.state.notAvailable
        }


        if (parseInt(accountType) === ACCOUNT_TYPE_ORGANISATION) {
            
            let type_name = 'Organisation';
            if (Number(this.state.type_of_shift) === 1) {
                type_name = 'Site';
            }
            popcontent_row1 = <tr><td><Icon
                assistiveText={{
                    label: type_name,
                }}
                category="standard"
                name="opportunity"
                style={{
                    backgroundColor: '#ea7600',
                    fill: '#ffffff',
                }}
                title={person_org}
            /></td>
                <td>
                    <div>{type_name} Name:</div>
                    <strong className="popval">{person_org}</strong>
                </td>
            </tr>

            popcontent_row2 = '';
        } else if (parseInt(accountType) === ACCOUNT_TYPE_CONTACT) {
            popcontent_row1 = <tr><td><Icon
                assistiveText={{
                    label: 'Participant Name',
                }}
                category="standard"
                name="channel_program_members"
                style={{
                    backgroundColor: '#0eb58a',
                    fill: '#ffffff',
                }}
                title={person_org}
            /></td>
                <td>
                    <div>Participant Name:</div>
                    <strong className="popval">{person_org}</strong>
                </td>
            </tr>

            popcontent_row2 = <tr><td className="padrow">
                <Icon
                    assistiveText={{
                        label: 'Contacts',
                    }}
                    category="standard"
                    name="opportunity"
                    style={{
                        backgroundColor: '#ea7600',
                        fill: '#ffffff',
                    }}
                    title="Contacts"
                />
            </td><td className="padrow">
                    <div>Contact:</div>
                    <strong className="popval">{this.state.contact_person ? this.state.contact_person.label : this.state.notAvailable}</strong>
                </td></tr>
        }

        const popcontent = <table className="shift_popover">
            {popcontent_row1}
            {popcontent_row2}
            <tr><td className="padrow">
                <Icon
                    assistiveText={{
                        label: 'Phone',
                    }}
                    category="standard"
                    name="call"
                    style={{
                        backgroundColor: '#0eb58a',
                        fill: '#ffffff',
                    }}
                    title="Phone"
                />
            </td><td className="padrow">
                    <div>Phone:</div>
                    <strong className="popval">{this.state.contactphone ? this.state.contactphone : this.state.notAvailable}</strong>
                </td></tr>
            <tr><td className="padrow">
                <Icon
                    assistiveText={{
                        label: 'Email',
                    }}
                    category="standard"
                    name="email"
                    style={{
                        backgroundColor: '#0040ff',
                        fill: '#ffffff',
                    }}
                    title="Phone"
                />
            </td><td className="padrow">
                    <div>Email:</div>
                    <strong className="popval">{this.state.contactemail ? <a href={"mailto:" + this.state.contactemail} title={this.state.contactemail}>{this.state.contactemail}</a> : this.state.notAvailable}</strong>
                </td></tr>
        </table>

        return <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
            <div>
                <Popover
                    ariaLabelledby="ALTERNATIVE-HEADING"
                    body={popcontent}
                    id="popover-alternative-header"
                    align="bottom"
                    hasNoNubbin="true"
                    hasNoCloseButton="true"
                    size="small"
                >
                    <a href="javascript:void(0)" className="reset" style={{ color: '#006dcc' }}>{person_org}</a>
                </Popover>
            </div>
        </IconSettings>
    }

    /**
     * Renders the label related to owner.
     */
    renderRelatedOwnerLink() {
        const owner = _.get(this.state, 'owner_person.label', null)
        if (!owner) {
            return this.state.notAvailable
        }
        return owner
    }

    /**
     * Renders the page header
     */
    renderPageHeader() {
        
        let orgDetail = [];
        let account_label = "Account";
        if (Number(this.state.type_of_shift) === 1) {
            let accountOrg = _.get(this.state, 'account_org.label');
            let accountSubOrg = _.get(this.state, 'account_sub_org.label');
            let accountService = _.get(this.state, 'service_area.label');
            
            if (!accountService) {
                accountService = this.props.notAvailable
            }
            if (!accountOrg) {
                accountOrg = this.props.notAvailable
            }
            if (!accountSubOrg) {
                accountSubOrg = this.props.notAvailable
            }    

            account_label = "Site";
            orgDetail = [
                {
                    label: 'Organisation',
                    content: accountOrg,
                },
                {
                    label: 'Sub Organisation',
                    content: accountSubOrg,
                }
            ];
        }
        const header = {
            icon: "Shift",
            label: "Shift",
            title: this.state.shift_no || '',
            details: [
                ...orgDetail,
                {
                    label: account_label,
                    content: this.renderRelatedAccountLink(),
                },
                {
                    label: 'Owner',
                    content: this.renderRelatedOwnerLink(),
                },
                {
                    label: 'Scheduled Start Time',
                    content: moment(this.state.scheduled_start_datetime).format("DD/MM/YYYY HH:mm")
                },
                {
                    label: 'Scheduled End Time',
                    content: moment(this.state.scheduled_end_datetime).format("DD/MM/YYYY HH:mm")
                },
                {
                    label: 'Duration (h)',
                    content: this.state.scheduled_duration
                },
                {
                    label: 'Address',
                    content: this.state.account_address ? getAddressForViewPage(this.state.account_address.label, this.state.account_address.unit_number) : ""
                }
            ],
        }

        return (
            <PageHeader
                details={header.details}
                icon={
                    <Icon
                        assistiveText={{
                            label: 'Shift',
                        }}
                        category="standard"
                        name="date_input"
                        title="Shift"
                    />
                }
                label={header.label}
                onRenderActions={this.actions}
                title={header.title}
                variant="record-home"
            />
        )
    }

    /**
     * Render related tab
     */
    renderRelatedTab() {
        const styles = css({
            card: {
                border: '1px solid #dddbda',
                boxShadow: '0 2px 2px 0 rgba(0,0,0,.1)',
            },
            attach_card: {
                margin: '15px 0px',
            }
        })



        return (
            <Tabs id="slds-related-tab">
                <TabsPanel label="Related">
                    <div className="">
                        <div className="" style={styles.attach_card}>
                            {this.state.showAttachment && <ShiftAttachmentCard
                                ref={e => this.attachmentRef = e}
                                attachments={this.state.attachment_details}
                                shift_status={this.state.status}
                                onClickEdit={(e, readOnly) => this.showModal('', 'timesheet', readOnly)}
                                onClickNotes={(e, readOnly) => this.showModal('', 'shift_notes', readOnly)}
                            />}
                        </div>
                        {this.state.checklist && <Card
                            id="checklist"
                            headerActions={<Button><Link to={ROUTER_PATH + `checklist/${this.state.checklist.participant_id}/${this.state.shift_no}/${this.state.shift_id}`} className="reset" style={{ color: '#0070d2' }}>View</Link></Button>}
                            heading={"Staff/Enviornment Checklist"}
                            style={styles.card}
                            icon={<Icon category="utility" size="small" name="list"
                                style={{
                                    backgroundColor: '#baac93',
                                    fill: '#ffffff'
                                }} />}
                        ></Card>}

                        {this.state.showAssessment && <AttachmentCard
                            object_type={AttachmentCard.OBJECT_TYPE_NEED_ASSESSMENT}
                            object_id={this.state.need_assessment_id}
                            attachments={this.state.attachments}
                            shift_id={this.state.shift_id}
                            account_id={this.state.account_person.value}
                        />}

                    </div>
                </TabsPanel>
            </Tabs>
        )


    }

    /**
     * Renders the details tab
     */
    renderDetailsTab = () => {
        const notAvailable = this.state.notAvailable

        
        var details = [];
        let type_of_shift = Number(this.state.type_of_shift);
        if(type_of_shift === 1) {
            details = [
                {
                    caption: "General Information"
                },                
                {
                    label: 'Type of shift',
                    value: "B2B Shift",
                    editIcn: false,
                },
                {
                    label: 'Shift Number',
                    value: this.state.shift_no || notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Owner',
                    value: this.state.owner_person ? this.state.owner_person.label : notAvailable,
                    editIcn: false,
                },                
                {
                    label: 'Organisation',
                    value: this.state.account_org ? this.state.account_org.label : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Sub Organisation',
                    value: this.state.account_sub_org ? this.state.account_sub_org.label : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Site',
                    value: this.state.account_person ? this.state.account_person.label : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Status',
                    value: this.state.status_label ? this.state.status_label : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Service Area',
                    value: this.state.service_area ? this.state.service_area.label : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Address',
                    value: this.state.account_address ? getAddressForViewPage(this.state.account_address.label, this.state.account_address.unit_number) : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Description',
                    value: this.state.description ? this.state.description : notAvailable,
                    editIcn: false,
                    cell_width: 1,
                },
            ];
        } else {
            details = [
                {
                    caption: "General Information"
                },
                {
                    label: 'Shift Number',
                    value: this.state.shift_no || notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Owner',
                    value: this.state.owner_person ? this.state.owner_person.label : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Account',
                    value: this.state.account_person ? this.state.account_person.label : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Status',
                    value: this.state.status_label ? this.state.status_label : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Service Type',
                    value: this.state.role_details ? this.state.role_details.label : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Address',
                    value: this.state.account_address ? getAddressForViewPage(this.state.account_address.label, this.state.account_address.unit_number) : notAvailable,
                    editIcn: false,
                },
                {
                    label: 'Description',
                    value: this.state.description ? this.state.description : notAvailable,
                    editIcn: false,
                    cell_width: 1,
                },
            ];
        }

        if (this.state.accepted_shift) {
            details.push({
                caption: "Scheduled Shift Times"
            },
                {
                    label: 'Shift Start Time',
                    value: this.state.scheduled_start_datetime ? moment(this.state.scheduled_start_datetime).format("DD/MM/YYYY HH:mm") : notAvailable,
                    editIcn: false,
                    cell_width: 3,
                },
                {
                    label: 'Shift End Time',
                    value: this.state.scheduled_end_datetime ? moment(this.state.scheduled_end_datetime).format("DD/MM/YYYY HH:mm") : notAvailable,
                    editIcn: false,
                    cell_width: 3,
                },
                {
                    label: 'Duration (h)',
                    value: this.state.scheduled_duration ? this.state.scheduled_duration : notAvailable,
                    editIcn: false,
                    cell_width: 3,
                });

            if (this.state.scheduled_paid_rows && this.state.scheduled_paid_rows.length > 0) {
                details.push({ caption: "Scheduled Paid Break Times" });
                var scheduled_paid_rows = this.fetchBreakTypes(this.state.scheduled_paid_rows);
                details = [...details, ...scheduled_paid_rows];
            }

            if (this.state.scheduled_unpaid_rows && this.state.scheduled_unpaid_rows.length > 0) {
                details.push({ caption: "Scheduled Unpaid Break Times" });
                var scheduled_unpaid_rows = this.fetchBreakTypes(this.state.scheduled_unpaid_rows);
                details = [...details, ...scheduled_unpaid_rows];
            }

            if (this.state.scheduled_sleepover_rows && this.state.scheduled_sleepover_rows.length > 0) {
                details.push({ caption: "Scheduled Sleepover Break Times" });
                var scheduled_sleepover_rows = this.fetchBreakTypes(this.state.scheduled_sleepover_rows);
                details = [...details, ...scheduled_sleepover_rows];
            }

            details.push({ caption: "Scheduled Allowances and Reimbursements" });
            details.push({
                label: 'Travel Allowance (KMs)',
                value: this.state.scheduled_travel ? this.state.scheduled_travel : notAvailable,
                editIcn: false,
            });
            details.push({
                label: 'Reimbursements ($)',
                value: this.state.scheduled_reimbursement ? this.state.scheduled_reimbursement : notAvailable,
                editIcn: false,
            });
            details.push({
                label: 'Commuting Travel Allowance (Distance KMs)',
                value: this.state.scheduled_travel_distance ? this.state.scheduled_travel_distance : notAvailable,
                editIcn: false,
            });
            details.push({
                label: 'Commuting Travel Allowance (Duration hrs)',
                value: this.state.scheduled_travel_duration ? this.getAllowanceDuration(this.state.scheduled_travel_duration) : notAvailable,
                editIcn: false,
            });

            details.push({
                caption: "Actual Shift Times"
            },
                {
                    label: 'Shift Start Time',
                    value: this.state.actual_start_datetime ? moment(this.state.actual_start_datetime).format("DD/MM/YYYY HH:mm") : notAvailable,
                    editIcn: false,
                    cell_width: 3,
                },
                {
                    label: 'Shift End Time',
                    value: this.state.actual_end_datetime ? moment(this.state.actual_end_datetime).format("DD/MM/YYYY HH:mm") : notAvailable,
                    editIcn: false,
                    cell_width: 3,
                },
                {
                    label: 'Duration (h)',
                    value: this.state.actual_duration ? this.state.actual_duration : notAvailable,
                    editIcn: false,
                    cell_width: 3,
                });

            if (this.state.actual_paid_rows && this.state.actual_paid_rows.length > 0) {
                details.push({ caption: "Actual Paid Break Times" });
                var actual_paid_rows = this.fetchBreakTypes(this.state.actual_paid_rows);
                details = [...details, ...actual_paid_rows];
            }

            if (this.state.actual_unpaid_rows && this.state.actual_unpaid_rows.length > 0) {
                details.push({ caption: "Actual Unpaid Break Times" });
                var actual_unpaid_rows = this.fetchBreakTypes(this.state.actual_unpaid_rows);
                details = [...details, ...actual_unpaid_rows];
            }

            if (this.state.actual_sleepover_rows && this.state.actual_sleepover_rows.length > 0) {
                details.push({ caption: "Actual Sleepover Break Times" });
                var actual_sleepover_rows = this.fetchBreakTypes(this.state.actual_sleepover_rows);
                details = [...details, ...actual_sleepover_rows];
            }

            if (this.state.actual_in_sleepover_rows && this.state.actual_in_sleepover_rows.length > 0) {
                details.push({ caption: "Actual Interrupted Sleepover Break Times" });
                var actual_in_sleepover_rows = this.fetchBreakTypes(this.state.actual_in_sleepover_rows);
                details = [...details, ...actual_in_sleepover_rows];
            }

            let support_type_duration = this.state['actual_support_type_duration'];
            let support_type_key_name = this.state['actual_support_type_key_name'];
            if (support_type_duration && support_type_key_name === 'mixed') {
                support_type_duration.map((sup_dur, idx) => {
                    let label = '';
                    let day_count = sup_dur.day_count;
                    let duration = sup_dur.duration;
                    if (day_count > 0) {
                        details.push({
                            label: sup_dur.label,
                            value: ' ',
                            null: true,
                            editIcn: false,
                            cell_width: 3,
                        });
                    } 
                    duration.map((dur, idx) => {
                        let duration_txt = dur.duration;
                        if (Number(dur.support_type) === 1) {
                            label = 'Self Care';
                        } else {
                            label = 'Comm Access';
                        } 
                        details.push({
                            label: label+" Duration (hh:mm)",
                            value: duration_txt,
                            editIcn: false,
                            cell_width: day_count > 0 ? 3 : 2,
                        });
                    });
                });
            }
            details.push({ caption: "Actual Allowances and Reimbursements" });
            details.push({
                label: 'Travel Allowance (KMs)',
                value: this.state.actual_travel ? this.state.actual_travel : notAvailable,
                editIcn: false,
            });
            details.push({
                label: 'Reimbursements ($)',
                value: this.state.actual_reimbursement ? this.state.actual_reimbursement : notAvailable,
                editIcn: false,
            });
            details.push({
                label: 'Commuting Travel Allowance (Distance KMs)',
                value: this.state.actual_travel_distance ? this.state.actual_travel_distance : notAvailable,
                editIcn: false,
            });
            details.push({
                label: 'Commuting Travel Allowance (Duration hrs)',
                value: this.state.actual_travel_duration ? this.getAllowanceDuration(this.state.actual_travel_duration) : notAvailable,
                editIcn: false,
            });
        }

        return (<React.Fragment>
            <div id="navbarheader" class="topnavbars">

                <Tabs id="slds-details-sub-tab" selectedIndex={this.state.currentTab} onSelect={($event) => { this.changeTab($event) }}>
                    <TabsPanel label="Details">
                        <div className='container-fluid m-0 pl-1 pr-1'>
                            <div class="slds-scrollable_y" style={{ "height": '100vh', 'display': 'initial' }}>
                                <DetailsTitle
                                    details={details}
                                />
                            </div>
                        </div>
                    </TabsPanel>
                { type_of_shift === 2 && <>
                    <TabsPanel label="Need Assessment" disabled={this.state.status >= 3 && this.state.need_assessment_id ? false : true}>
                        <div className='container-fluid m-0 pl-1 pr-1'>
                            <div class="slds-scrollable_y" style={{ "height": '100vh', 'display': 'initial' }}>
                                {this.state.showAssessment && <ShiftNeedAssessmentCard
                                    need_assessment_id={this.state.need_assessment_id}
                                />}
                            </div>
                        </div>
                    </TabsPanel>
                    <TabsPanel label="Risk Assessment" disabled={this.state.status >= 3 && this.state.risk_assessment_id ? false : true}>
                        <div className='container-fluid m-0 pl-1 pr-1'>
                            <div class="slds-scrollable_y" style={{ "height": '100vh', 'display': 'initial' }}>
                                {this.state.showAssessment && <ShiftRiskAssessmentCard
                                    risk_assessment_id={this.state.risk_assessment_id}
                                />}
                            </div>
                        </div>
                    </TabsPanel>
                    <TabsPanel label="Previous Shift" disabled>
                        <div className='container-fluid m-0 pl-1 pr-1'>
                            <div class="slds-scrollable_y" style={{ "height": '100vh', 'display': 'initial' }}>
                                <ShiftPreviousShiftCard
                                />
                            </div>
                        </div>
                    </TabsPanel>
                    <TabsPanel label="Goal Tracking" disabled={this.state.status >= 3 ? false : true}>
                        <div className='container-fluid m-0 pl-1 pr-1'>
                            <div class="slds-scrollable_y" style={{ "height": '100vh', 'display': 'initial' }}>
                                {this.state.account_person && <ShiftGoalTracking
                                    account_id={this.state.account_person.value}
                                    account_type={this.state.account_person.account_type}
                                />}
                            </div>
                        </div>
                    </TabsPanel>
                </>
                }
                </Tabs>
            </div>


        </React.Fragment>
        )
    }

    /**
     * fetches the scheduled breaks information block
     */
    fetchBreakTypes(breaks) {
        var retarr = [];
        if (breaks && breaks.length > 0) {
            breaks.map((row, idx) => {
                retarr.push(
                    {
                        label: 'Start Time',
                        value: row.break_start_time,
                        editIcn: false,
                        cell_width: 3
                    },
                    {
                        label: 'End Time',
                        value: row.break_end_time,
                        editIcn: false,
                        cell_width: 3
                    },
                    {
                        label: 'Duration',
                        value: row.break_duration,
                        editIcn: false,
                        cell_width: 3
                    });
            });
        }
        return retarr;
    }

    /**
     * after no shift info or member assignment not found
     */
    redirectToListing() {
        this.setState({ redirectTo: ROUTER_PATH + `member` });
    }

    /**
     * Open create shift modal
     */
    showModal = (shift_id, modal_name, readOnly = false) => {
        this.setState({ openCreateModal: true, shift_id: shift_id, modal_name: modal_name, readOnly });
        let id = _.get(this.props, 'props.match.params.id');
        // Lock object
        lockObject(id, 'shift');
    }

    /**
     * Close the modal when user save the shift and refresh the table
     */
    closeAddEditShiftModal = (status) => {
        this.setState({ openCreateModal: false, isFirstRender: false });
        const id = _.get(this.props, 'props.match.params.id');
        if (status) {            
            this.get_shift_details(id);
            this.get_shift_member_details_frm_member_id(id);
        }
        // Unlock Object
        unlockObject(id, 'shift');
    }

    /**
     * rendering components
     */
    render() {
        // This will only run when you archive this shift
        if (!this.state.showAssessment) {
            return '';
        }
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }

        const styles = css({
            root: {
                fontFamily: 'Salesforce Sans, Arial, Helvetica, sans-serif',
                marginRight: -15,
                fontSize: 13,
            }
        })



        return (
            <div className="ShiftDetails slds" style={styles.root} ref={this.rootRef}>
                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                    <div className="col-lg-12">
                        <div className="slds-col custom_page_header">
                            {this.renderPageHeader()}
                        </div>

                        {this.state.accepted_shift ? <div className="slds-col slds-m-top_medium slds-theme_default slds-page-header">
                            <ScheduleStatusPath {...this.state} get_shift_details={this.get_shift_details}
                                showModal={this.showModal}
                            />
                        </div> : ''}

                        {this.state.moreLoaded && <SplitContainer
                            rightColContent={this.state.accepted_shift ? this.renderRelatedTab() : ''}
                            leftColContent={this.renderDetailsTab()}
                            right_cols={4}
                            left_cols={this.state.accepted_shift ? 8 : 12}
                        />}

                        {openAddEditShiftModal(this.state.id, this.state.openCreateModal, this.closeAddEditShiftModal, this.state.modal_name, this.state.status, this.state.readOnly)}
                    </div>
                </IconSettings>
                {this.state.showAlert&&(<SystemAlertPopup  
        closeAlert ={this.closeAlert}
        shiftStatus={this.state.shiftStatus}
        alertMessage={this.state.alertMessage}/>)}
            </div>
        );
    }

}
const mapStateToProps = state => ({
    ...state.ContactReducer,
})

const mapDispatchtoProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchtoProps)(ShiftDetails);