import React, { Component } from 'react';
import jQuery from "jquery";
import 'react-select-plus/dist/react-select-plus.css';
import { checkItsNotLoggedIn, postData, toastMessageShow, getApplicantId, getMemberId } from 'service/common.js';
import { Icon, ExpandableSection, Radio } from '@salesforce/design-system-react';
import 'react-block-ui/style.css';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import 'service/jquery.validate.js';
import "service/custom_script.js";

class ShiftNotes extends Component {

    /**
     * setting the initial prop values
     */
    constructor(props) {
        super(props);
        checkItsNotLoggedIn();

        const TIME_FORMAT = 'hh:mm A';

        const GOAL_TYPE = [
            { id: 'not_relevant', label: 'Not Attempted:Not relevant to this shift', value: '1' },
            { id: 'customer_choice', label: "Not Attempted:Customer's Choice", value: '2' },
            { id: 'verbal_prompt', label: 'Verbal Prompt', value: '3' },
            { id: 'physical_assistance', label: 'Physical Assistance', value: '4' },
            { id: 'participant_proactivity', label: 'Participant Proactivity', value: '5' }
        ];

        const OUTCOME_TYPE =[
            { id: 'achieved', label: 'Achieved', value: '1' },
            { id: 'partially_achieved', label: "Partially Achieved", value: '2' },
        ]
         //as part of hcm-8307-renamed independent to Participant Proactivity
        this.state = {
            loading: false,
            id: '',
            shift_no: '',
            time_format: TIME_FORMAT,
            scheduled_reimbursement: '',
            scheduled_travel: '',
            notes: '',
            break_types: [],
            status: 1,
            goal_type: GOAL_TYPE,
            goalDetails: [],
            goalSelection: [],
            snapShotList:[],
            incident_occur_today: "1",
            selected_array: [],
            outcome_type:OUTCOME_TYPE,
            outcome_array:[],
            outcome_selection:[]
        }
        
    }

    /**
     *
     * @param {int} id shift id
     * get Shift goal details
     */
     get_shift_goal_tracking_details = (id) => {
        this.setState({loading: true});
        postData('recruitment/Recruitment_member/get_shift_goal_tracking_details', { id }).then((result) => {
            this.setState({loading: false});
            if (result.status) {
                this.setState(result.data.goals_notes_reports);
               
                if(result.data.goals_tracking.length > 0){
                     let goaldata = this.state.selected_array;
                    result.data.goals_tracking.forEach(element => {
                        goaldata.forEach((selected,idx) => {
                            if(element.goal_id==selected.goal_id){
                                goaldata[idx].type = element.goal_type;
                                goaldata[idx].checked = true;
                                goaldata[idx].outcome_type = element.outcome_type;
                                goaldata[idx].outcome_checked = goaldata[idx].outcome_type>0?true:false;
                            }
                        });
                    });                   
                    this.setState({selected_type: result.data.goals_tracking[0].goal_type, goalSelection : [], selected_array: goaldata }, () => {});
                }
               let goals_notes_reports = result.data.goals_notes_reports;
                goals_notes_reports.incident_occur_today == "2" ? this.setState({incident_occur_today: "2"}) : this.setState({incident_occur_today: "1"});
                goals_notes_reports.incident_report == "2" ? this.setState({incident_report: "2"}) : this.setState({incident_report: "1"});
            }
        });
    }

    /**
     * handling the status change event
     */
    handleChange = (value, key) => {

        if(key === 'incident_occur_today' && value == "1") {
            jQuery('#incident_report').attr('required', true);
        }
        else if(key === 'incident_occur_today' && value == "2") {
            jQuery('#incident_report').removeAttr('required');
        }
        this.setState({ [key]: value });
    }

    handleRadioBtnSelect = (value, goal_id) => {
        this.setState({goalSelection : []}, ()=>{
            let data = this.state.goalSelection;
            let tempArr = [];
            jQuery('#snap_shot_' + goal_id).removeAttr('required');
            jQuery('#snap_shot_required_' + goal_id).hide();
            jQuery('#goal_outcome_required_' + goal_id).hide();
            let goal=`outcome_type_${goal_id}` 
            jQuery(`input[name=${goal}]`).attr("required", false);  
            if( jQuery(`input[name=${goal}]`).attr("aria-describedby"))
            {    let tooltip_id=jQuery(`input[name=${goal}]`).attr("aria-describedby");
                jQuery(`#${tooltip_id}`).hide();
            }
        
          
           
          //  jQuery(`input[name=${goal}]`).removeClass( 'error' );   
           // aria-describedby
            if(data.length > 0 ) {
                for (let i = 0; i < data.length; i++) {
                    tempArr.push(data[i]);
                    if (data[i].goal_id && data[i].goal_id == goal_id) {
    
                        //Remove the row based on the index
                        tempArr.splice(i, 1);
                    }
                }
            }
    
            //Add required validation to text area
            if(value != 1) {
                jQuery('#snap_shot_' + goal_id).attr('required', true);
                jQuery('#snap_shot_required_' + goal_id).show();
            }
            if(value<3){
                let goal=`outcome_type_${goal_id}` 
                jQuery(`input[name=${goal}]`).attr("required", true);  
                jQuery('#goal_outcome_required_' + goal_id).show();
            }
    
            tempArr.push({goal_id: goal_id, type: value});
            this.setState({goalSelection: tempArr,  selected_type : value});
        });

        let clone = this.state.selected_array;
        this.state.selected_array.forEach((e, i) => {
            if (e.goal_id === goal_id && (e.type !== '' && e.type !== value) && e.checked) {
                clone[i].checked = true;
                clone[i].type = value;
            } else if (e.goal_id === goal_id && e.type === '' && !e.checked) {
                clone[i].checked = true;
                clone[i].type = value;
            }
        });

        this.setState({ selected_array: clone});
    }


    handleOutcomeRadioBtnSelect = (value, goal_id) => {
        let tempArr=[];
        tempArr.push({goal_id: goal_id, type: value});
        this.setState({outcome_selection: tempArr});
        let clone = this.state.selected_array;
        this.state.selected_array.forEach((e, i) => {
            if (e.goal_id === goal_id && (e.outcome_type !== '' && e.type !== value) && e.outcome_checked) {
                clone[i].outcome_checked = true;
                clone[i].outcome_type = value;
            } else if (e.goal_id === goal_id && e.outcome_type === '' && !e.outcome_checked) {
                clone[i].outcome_checked = true;
                clone[i].outcome_type = value;
            }
        });

        this.setState({ selected_array: clone});
    }

    /**
     *
     * @param {string} value - snapshot text area value
     * @param {*} goal_id - Goal id of the snapshot
     */
    handleSnapshotList = (value, goal_id) => {

        let snaptempArr = [];
       
        this.state.goalDetails.map((val) =>{
            if(val.goal_id == goal_id){
                val.snapshot = value;
            }
        })
        this.state.goalDetails.forEach((e, i) => {
            snaptempArr.push({goal_id : e.goal_id , value: e.snapshot});
        });

        this.setState({snapShotList: snaptempArr});
    }   

    /**
     * when submit button is clicked on the modal
     */
    onSubmit = (e) => {
        var default_submit = false;
        if(e) {
            e.preventDefault();
            default_submit = true;
        }
        jQuery("#create_shift").validate({ /* */ });
       
        this.setState({ validation_calls: true })
        //Add required attribute to goal type
        jQuery('.goal_type [type=radio]').attr("required", true);
        jQuery("#create_shift").valid()
        if (!this.state.loading && jQuery("#create_shift").valid()) {
            this.setState({ loading: true });

            var req = { id: this.state.id,
                applicant_id: getApplicantId(),
                member_id: getMemberId(),
                shift_no: this.state.shift_no,
                notes: this.state.notes,
                goalDetails: this.state.goalDetails,
                goalSelection: this.state.selected_array,
                snapShotList: this.state.snapShotList,
                incident_occur_today: this.state.incident_occur_today,
                incident_report: this.state.incident_report,
                task_taken: this.state.task_taken,
                worked_well: this.state.worked_well,
                done_better: this.state.done_better,
                created_user_type : 2 // Created by member

            }
            postData('recruitment/Recruitment_member/create_update_shift_goals_portal', req).then((result) => {
                if (result.status) {
                    let msg = result.hasOwnProperty('msg') ? result.msg : '';
                    toastMessageShow(result.msg, "s");
                    this.props.closeModal(true);
                } else {
                    toastMessageShow(result.error, "e");
                }
                this.setState({ loading: false });
            });
        }
    }

    /**
     * mounting all the components
     */
    componentDidMount() {
        if (this.props.id) {
            this.get_shift_details(this.props.id);
            
        }

        
    }

    /**
     * fetching the member details if the modal is opened in the edit mode
     */
    get_shift_details = (shift_id) => {
        var req = {id: shift_id, applicant_id: getApplicantId(), member_id: getMemberId()}
        postData('recruitment/Recruitment_member/get_shift_details', req).then((result) => {
            if (result.status) {
                let actual_travel_duration = result.data.actual_travel_duration;

                if(actual_travel_duration && actual_travel_duration.indexOf(":") !== -1 ) {
                    let actDuration = actual_travel_duration.split(":");
                    result.data.actual_travel_duration_hr = actDuration[0];
                    result.data.actual_travel_duration_min = actDuration[1];
                }
                //Get goal details against participant
                if(Number(result.data.type_of_shift) === 2 && (result.data.account_type == 1 || result.data.account_type == 2)) {
                    this.get_goal_details(result.data.account_id, shift_id);
                }
                this.setState(result.data);
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }

    /**
     * Fetching the particiapnt goal details
     */
    get_goal_details = (participantId, shift_id) => {
        let req = {shift_id: shift_id, applicant_id: getApplicantId(), member_id: getMemberId(),participant_id: participantId, pageSize: 9999, page: 0 }
        postData('recruitment/Recruitment_member/get_goals_list', req).then((result) => {
            if (result.status) {
                this.setState({goalDetails: result.data});

                let goaldata = [];
                if(result.data.length > 0){
                    result.data.forEach(element => {
                           goaldata.push({goal_id: element.goal_id, type: '', checked: false,outcome_type:'',outcome_checked:false});
                    });
                }
               
               
                this.setState({selected_array: goaldata },()=>{
                    this.get_shift_goal_tracking_details(this.props.id);
                });
            }
        });
    }

    /**
     * rendering general part of the schedule form
     */
    RenderGeneralSection() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row py-2">
                        <div className="col-lg-6 col-sm-6">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" >
                                    <abbr className="slds-required" title="required">* </abbr>Shift No:
                                </label>
                                <div className="slds-form-element__control">
                                {this.state.shift_no || ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    /**
     * Rendering Goal tracking of the schedule form
     */
     RenderGoalTrackingSection() {
        return (
            <React.Fragment>
                <ExpandableSection
					id="default-expandable-section"
					title="Goal Tracking"
				>
                <div className="container-fluid">
                    <div className="row py-2">
                        <div className="col-lg-12 col-sm-12">
                            {(this.state.goalDetails.length > 0 && this.state.selected_array.length > 0) ?
                                this.state.goalDetails.map((goalrow, goalidx) => {

                                    return(
                                    <div className="slds-form-element">
                                        <label className="slds-form-element__label" htmlFor="select-01">
                                        <abbr className="slds-required" title="required" style={{fontWeight:600}}>* </abbr>
                                            {goalrow.goal_title}</label>
                                        <div className="slds-form-element__control">
                                            {

                                                this.state.goal_type.map((row, idx) => {
                                                    return(
                                                        <div className="col-lg-6 col-sm-6">
                                                            <Radio
                                                                name={'goaltype' + goalrow.goal_id}
                                                                onChange={(e) => this.handleRadioBtnSelect(row.value, goalrow.goal_id)}
                                                                label={row.label}
                                                                value={row.value}
                                                                className={'goal_type'}
                                                                checked = {this.state.selected_array[goalidx].checked && this.state.selected_array[goalidx].type == row.value && goalrow.goal_id == this.state.selected_array[goalidx].goal_id}
                                                                disabled={this.props.readOnly}
                                                                />
                                                        </div>
                                                        )
                                                })
                                            }

                                        </div>
                                        <div className="col-lg-12 col-sm-12">
                                        <label className="slds-form-element__label" htmlFor="select-01">
                                            <abbr class="slds-required" title="required"
                                             style={{display: 'none'}}
                                            id={'goal_outcome_required_'+goalrow.goal_id } >* </abbr>
                                                Outcome</label>
                                                </div>
                                                <div className="slds-form-element__control">
                                            {

                                                this.state.outcome_type.map((row, idx) => {
                                                    return(
                                                        <div className="col-lg-6 col-sm-6">
                                                            <Radio
                                                                name={'outcome_type_' + goalrow.goal_id}
                                                                onChange={(e) => this.handleOutcomeRadioBtnSelect(row.value, goalrow.goal_id)}
                                                                label={row.label}
                                                                value={row.value}
                                                                required
                                                                className={'outcome_type_'+goalrow.goal_id}
                                                                checked = {this.state.selected_array[goalidx].outcome_checked && 
                                                                    this.state.selected_array[goalidx].outcome_type == row.value && 
                                                                    goalrow.goal_id == this.state.selected_array[goalidx].goal_id}
                                                                disabled={this.props.readOnly}
                                                                />
                                                        </div>
                                                        )
                                                })
                                            }
                                            </div>
                                        

                                     
                                        
                                        <div className="slds-form-element__control">
                                            <label className="slds-form-element__label" htmlFor="select-01">
                                            <abbr class="slds-required" title="required"
                                            style={{display: 'none'}}
                                            id={'snap_shot_required_' + goalrow.goal_id }>* </abbr>
                                                Snapshot</label>
                                            <textarea
                                                className="slds-textarea"
                                                name={'snap_shot_' + goalrow.goal_id }
                                                id={'snap_shot_' + goalrow.goal_id }
                                                onChange={(e) => this.handleSnapshotList(e.target.value, goalrow.goal_id)}
                                                value={goalrow.snapshot}
                                                disabled={this.props.readOnly}
                                            />
                                        </div>
                                </div>)
                            }) : <div className="slds-form-element">N/A</div>
                            }
                        </div>
                    </div>
                </div>
                </ExpandableSection>
            </React.Fragment>
        )
     }

    /**
     * Rendering Goal shift notes section of the schedule form
     */
     RenderGoalShiftNotesSection() {
        return (
            <React.Fragment>
                <ExpandableSection
					id="default-expandable-section"
					title="Shift Notes"
				>
                <div className="container-fluid">
                    <div className="row py-2">
                        <div className="col-lg-12 col-sm-12">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01">
                                <abbr className="slds-required" title="required">* </abbr>
                                    What tasks were undertaken today?</label>
                                <div className="slds-form-element__control">
                                    <textarea
                                        className="slds-textarea"
                                        name="task_taken"
                                        onChange={(e) => this.handleChange(e.target.value, "task_taken")}
                                        value= {this.state.task_taken ?  this.state.task_taken : ''}
                                        required
                                        readOnly={this.props.readOnly}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-12">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01">
                                <abbr className="slds-required" title="required">* </abbr>
                                    What worked well?</label>
                                <div className="slds-form-element__control">
                                    <textarea
                                        className="slds-textarea"
                                        name="worked_well"
                                        onChange={(e) => this.handleChange(e.target.value, "worked_well")}
                                        value= {this.state.worked_well ?  this.state.worked_well : ''}
                                        required
                                        readOnly={this.props.readOnly}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-12">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01">
                                <abbr className="slds-required" title="required">* </abbr>
                                    What could have been done better?</label>
                                <div className="slds-form-element__control">
                                    <textarea
                                        className="slds-textarea"
                                        name="done_better"
                                        onChange={(e) => this.handleChange(e.target.value, "done_better")}
                                        value= {this.state.done_better ?  this.state.done_better : ''}
                                        required
                                        readOnly={this.props.readOnly}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </ExpandableSection>
            </React.Fragment>
        )
     }

      /**
     * Rendering Goal shift Incident section of the schedule form
     */
       RenderGoalShiftIncidentSection() {
        return (
            <React.Fragment>
                <ExpandableSection
					id="default-expandable-section"
					title="Incident Report"
				>
                <div className="container-fluid">
                    <div className="row py-2">
                        <div className="col-lg-12 col-sm-12">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01">
                                    Did an incident occur today?</label>
                                <div className="slds-form-element__control">
                                    <div className="col-lg-2 col-sm-2">
                                        <Radio
                                        name="incident_occur_today_radio"
                                        value={"1"}
                                        checked={this.state.incident_occur_today == "1" ? true : false}
                                        onChange={(e) => this.handleChange(e.target.value, "incident_occur_today")}
                                        label="Yes"
                                        disabled={this.props.readOnly}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-sm-2">
                                        <Radio
                                        name="incident_occur_today_radio"
                                        checked={this.state.incident_occur_today == "2" ? true : false}
                                        onChange={(e) => this.handleChange(e.target.value, "incident_occur_today")}
                                        value={"2"}
                                        label="No"
                                        disabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-sm-12">
                            <div className="slds-form-element">
                                <label className="slds-form-element__label" htmlFor="select-01">
                                <abbr className="slds-required" title="required"
                                 style={{display:  this.state.incident_occur_today == 1 ? 'inline-block' : 'none' }}
                                >* </abbr>
                                    Have you submitted an incident report?</label>
                                <div className="slds-form-element__control">
                                    <div className="col-lg-2 col-sm-2">
                                        <Radio
                                        name="incident_report"
                                        id={"incident_report"}
                                        checked={this.state.incident_report =="1" ? true : false}
                                        onChange={(e) => this.handleChange(e.target.value, "incident_report")}
                                        value={"1"}
                                        label="Yes"
                                        disabled={this.props.readOnly}
                                        />
                                    </div>

                                    <div className="col-lg-2 col-sm-2">
                                        <Radio
                                        name="incident_report"
                                        checked={this.state.incident_report =="2" ? true : false}
                                        onChange={(e) => this.handleChange(e.target.value, "incident_report")}
                                        value={"2"}
                                        label="No"
                                        disabled={this.props.readOnly}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </ExpandableSection>
            </React.Fragment>
        )
     }

    /**
     * rendering components
     */
    render() {
        return (
            <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                <div>
                    <Modal
                        isOpen={this.props.showModal}
                        footer={[
                            <Button disabled={this.state.loading} label="Cancel" onClick={() => this.props.closeModal(false)} />,
                            !this.props.readOnly && <Button disabled={this.state.loading} label="Save" variant="brand" onClick={this.onSubmit} />
                        ]}
                        onRequestClose={this.toggleOpen}
                        heading={this.props.id ? this.props.title : ""}
                        size="small"
                        className="slds_custom_modal add_edit_shift_modal"
                        id="add_edit_shift"
                        onRequestClose={() => this.props.closeModal(false)}
                        dismissOnClickOutside={false}
                    >

                    <section className="manage_top" >
                            <form id="create_shift" autoComplete="off" className="slds_form">
                                {this.RenderGeneralSection()}
                                {Number(this.state.type_of_shift) === 2 && this.RenderGoalTrackingSection()}
                                {this.RenderGoalShiftNotesSection()}
                                {this.RenderGoalShiftIncidentSection()}
                            </form>
                    </section>

                    </Modal>
                </div>
            </IconSettings>
        );
    }
}

export default ShiftNotes;
