import React from 'react'
import { css } from '../../../../service/common'
import { IconSettings } from '@salesforce/design-system-react';
import SLDSReactSelect from 'components/admin/salesforce/lightning/SLDSReactSelect.jsx';
import DiagnosisCard from './NeedAssesmentDetails/DiagnosisCard.jsx';
import PreferencesCard from './NeedAssesmentDetails/PreferencesCard.jsx';
import CommunicationCard from './NeedAssesmentDetails/Communication.jsx';
import NutritionalSupportCard from './NeedAssesmentDetails/NutritionalSupportCard.jsx';
import MobilityCard from './NeedAssesmentDetails/MobilityCard.jsx';
import EquipmentCard from './NeedAssesmentDetails/EquipmentCard.jsx';
import HealthCard from './NeedAssesmentDetails/HealthCard.jsx';
import MedicationCard from './NeedAssesmentDetails/MedicationCard.jsx';
import PersonalCareCard from './NeedAssesmentDetails/PersonalCareCard.jsx';
import '../../scss/components/admin/crm/pages/sales/opportunity/OpportunityDetails.scss'


/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class ShiftNeedAssessmentCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            existingAttachments: this.props.attachments,
            selected_need_assessment: 'diagnosis'
        }
    }

    /**
     * handling the status change event
     */
     handleChange = (value, key) => {
        this.setState({ [key]: value });
    }

    render() {
        const styles = css({
            card: {
                border: '1px solid #dddbda',
                boxShadow: '0 2px 2px 0 rgba(0,0,0,.1)',
            },
            rowParent: {
                borderRadius: 0,
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
            },
        })


        return (
            <React.Fragment>
                <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">

                    {/* <div className="col-lg-6 col-sm-6"> */}
                    <div className="slds-form-element">
                        <div className="slds-form-element__control">

                            <div className="select_options_des">
                                <SLDSReactSelect
                                    simpleValue={true}
                                    className="custom_select default_validation"
                                    options={[
                                        { label: 'Diagnosis', value: 'diagnosis' },
                                        { label: 'Preferences', value: 'preferences' },
                                        { label: 'Communication', value: 'communication' },
                                        { label: 'Nutritional Support', value: 'nutritional_support' },
                                        { label: 'Mobility', value: 'mobility' },
                                        { label: 'Equipment', value: 'equipment' },
                                        { label: 'Health', value: 'health' },
                                        { label: 'Medication', value: 'medication' },
                                        { label: 'Personal care', value: 'personal_care' },
                                    ]}
                                    onChange={value => {
                                        this.handleChange(value, 'selected_need_assessment')
                                    }}
                                    value={this.state.selected_need_assessment}
                                    clearable={false}
                                    searchable={false}
                                    placeholder="Please Select"
                                    required={true}
                                    disabled={this.state.account_person === '' ? true : false}
                                    name="Service Type"
                                />
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                    <div>
                      {this.state.selected_need_assessment=='diagnosis' &&  <DiagnosisCard
                            attachments={this.state.attachment_details}
                            shift_status={this.state.status}
                            {...this.state}
                            {...this.props}
                        />}
                    
                    {this.state.selected_need_assessment=='preferences' && <PreferencesCard                           
                            {...this.state}
                            {...this.props}
                        />}
                    
                        {this.state.selected_need_assessment=='communication' && <CommunicationCard
                           {...this.state}
                           {...this.props}
                        />}
                   
                        {this.state.selected_need_assessment=='nutritional_support' && <NutritionalSupportCard
                             {...this.state}
                             {...this.props}
                        />}
                    
                        {this.state.selected_need_assessment=='mobility' &&  <MobilityCard
                            {...this.state}
                            {...this.props}
                        />}
                   
                        {this.state.selected_need_assessment=='equipment' &&  <EquipmentCard
                             {...this.state}
                             {...this.props}
                        />}
                    
                        {this.state.selected_need_assessment=='health' && <HealthCard
                             {...this.state}
                             {...this.props}
                        />}
                    
                        {this.state.selected_need_assessment=='medication' && <MedicationCard
                           {...this.state}
                           {...this.props}
                        />}
                    
                        {this.state.selected_need_assessment=='personal_care' && <PersonalCareCard
                         {...this.state}
                         {...this.props}
                    />}
                    </div>

                </IconSettings>
            </React.Fragment >
        )
    }
}

export default ShiftNeedAssessmentCard