import React from 'react';
import { css, postData, comboboxFilterAndLimit } from '../../../../../service/common';
import Textarea from '@salesforce/design-system-react/lib/components/textarea';

/**
 * @typedef {typeof AttachmentCard.defaultProps} Props
 * 
 * Card to display uploaded attachments
 * 
 * @extends {React.Component<Props>}
 */
class NutritionalSupportCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
     
    }
  }

  componentDidMount() {
    if (this.props.need_assessment_id) {
      this.getSelectedMealAssistance(this.props.need_assessment_id);
    }
  }

  getSelectedMealAssistance = (id) => {
    postData("sales/NeedAssessment/get_selected_nutritional_support", { need_assessment_id: id }).then((res) => {
      if (res.status) {
        this.setState(res.data.nutritional_support);
        this.setState({ attachment_details: res.data.attachment_data, selected_food_preferences: res.data.food_preferences }, () => {
          let arr = this.state.selected_food_preferences;
          if (arr && arr.length > 0) {
            let check_other = arr.some(code => code.label === 'Other'); // true
            if (check_other) {
              this.setState({ show_food_desc: true })
            } else {
              this.setState({ show_food_desc: false, food_preferences_desc: '' })
            }
          }
        });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="slds-grid">
          <div className="slds-panel slds-size_full slds-is-open" aria-hidden="false">
            <form id="mealtime_form" autoComplete="off" className="col-md-12 slds_form" onSubmit={e => this.onSubmit(e)}>
              <div className="slds-panel__header">
                <h2 className="slds-panel__header-title slds-text-heading_small slds-truncate" title="Panel Header">Nutritional Support</h2>
              </div>
              <div className="slds-panel__body">
                <fieldset className="slds-form-element">
                  <legend className="slds-form-element__legend slds-form-element__label">
                    Do you require support with Eating and Swallowing ?</legend>
                  <div className="slds-form-element__control">
                    <span className="slds-radio slds-float_left">
                      <input type="radio" value="1" id="support_with_eating_no" name="support_with_eating" disabled checked={(this.state.support_with_eating && this.state.support_with_eating == 1) ? true : false} />
                      <label className="slds-radio__label" htmlFor="support_with_eating_no">
                        <span className="slds-radio_faux"></span>
                        <span className="slds-form-element__label">No</span>
                      </label>
                    </span>
                    <span className="slds-radio slds-float_left">
                      <input type="radio" value="2" id="assisstance_plan_yes" name="support_with_eating" disabled checked={(this.state.support_with_eating && this.state.support_with_eating == 2) ? true : false} />
                      <label className="slds-radio__label" htmlFor="assisstance_plan_yes">
                        <span className="slds-radio_faux"></span>
                        <span className="slds-form-element__label">Yes</span>
                      </label>
                    </span>
                  </div>
                </fieldset>

                <fieldset className="slds-form-element">
                  <legend className="slds-form-element__legend slds-form-element__label"> Is there a Risk of aspiration</legend>
                  <div className="slds-form-element__control">
                    <span className="slds-radio slds-float_left">
                      <input type="radio" id="aspiration_no" value="1" name="risk_aspiration" disabled checked={(this.state.risk_aspiration && this.state.risk_aspiration == 1) ? true : false} />
                      <label className="slds-radio__label" htmlFor="aspiration_no">
                        <span className="slds-radio_faux"></span>
                        <span className="slds-form-element__label">No</span>
                      </label>
                    </span>
                    <span className="slds-radio slds-float_left">
                      <input type="radio" id="aspiration_yes" value="2" name="risk_aspiration" disabled checked={(this.state.risk_aspiration && this.state.risk_aspiration == 2) ? true : false} />
                      <label className="slds-radio__label" htmlFor="aspiration_yes">
                        <span className="slds-radio_faux"></span>
                        <span className="slds-form-element__label">Yes</span>
                      </label>
                    </span>
                  </div>
                </fieldset>


                {this.state.risk_aspiration == '2' && <div className="slds-form-element__control row">
                  <span className="slds-checkbox slds-float_left col col-sm-6">
                    <input type="checkbox" name="aspiration_food" id="aspiration_food" disabled checked={(this.state.aspiration_food && this.state.aspiration_food == '1') ? true : false} />
                    <label className="slds-checkbox__label" htmlFor="aspiration_food">
                      <span className="slds-checkbox_faux"></span>
                      <span className="slds-form-element__label"> Food</span>
                    </label>
                  </span>
                </div>}

                {this.state.risk_aspiration == '2' && this.state.aspiration_food == '1' && <div className="slds-form-element__control row">
                  <fieldset className="slds-form-element mb-3">
                    <div className="col-md-6"><label className="slds-form-element__legend slds-form-element__label">Description</label>
                      <div className="slds-form-element__control">
                        <div className="SLDS_date_picker_width">
                          <Textarea
                            type="text"
                            className="slds-input"
                            name="aspiration_food_desc"
                            placeholder="Enter the details of “Food”"
                            value={this.state.aspiration_food_desc}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>}

                {this.state.risk_aspiration == '2' && <div className="slds-form-element__control row">
                  <span className="slds-checkbox slds-float_left col col-sm-6">
                    <input type="checkbox" name="aspiration_fluids" id="aspiration_fluids" disabled checked={(this.state.aspiration_fluids && this.state.aspiration_fluids == '1') ? true : false} />
                    <label className="slds-checkbox__label" htmlFor="aspiration_fluids">
                      <span className="slds-checkbox_faux"></span>
                      <span className="slds-form-element__label"> Fluids</span>
                    </label>
                  </span>
                </div>}

                {this.state.risk_aspiration == '2' && this.state.aspiration_fluids == '1' && <div className="slds-form-element__control row">
                  <fieldset className="slds-form-element mb-3">
                    <div className="col-md-6"><label className="slds-form-element__legend slds-form-element__label">Description</label>
                      <div className="slds-form-element__control">
                        <div className="SLDS_date_picker_width">
                          <Textarea
                            type="text"
                            className="slds-input"
                            name="aspiration_fluids_desc"
                            placeholder="Enter the details of “Fluids”"
                            value={this.state.aspiration_fluids_desc}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>}

                <fieldset className="slds-form-element">
                  <legend className="slds-form-element__legend slds-form-element__label"> Is there a Risk of choking</legend>
                  <div className="slds-form-element__control">
                    <span className="slds-radio slds-float_left">
                      <input type="radio" id="choking_no" value="1" name="risk_choking" disabled checked={(this.state.risk_choking && this.state.risk_choking == 1) ? true : false} />
                      <label className="slds-radio__label" htmlFor="choking_no">
                        <span className="slds-radio_faux"></span>
                        <span className="slds-form-element__label">No</span>
                      </label>
                    </span>
                    <span className="slds-radio slds-float_left">
                      <input type="radio" id="choking_yes" value="2" name="risk_choking" disabled checked={(this.state.risk_choking && this.state.risk_choking == 2) ? true : false} />
                      <label className="slds-radio__label" htmlFor="choking_yes">
                        <span className="slds-radio_faux"></span>
                        <span className="slds-form-element__label">Yes</span>
                      </label>
                    </span>
                  </div>
                </fieldset>

                {this.state.risk_choking == '2' && <div className="slds-form-element__control row">
                  <span className="slds-checkbox slds-float_left col col-sm-6">
                    <input type="checkbox" name="choking_food" id="choking_food" disabled checked={(this.state.choking_food && this.state.choking_food == '1') ? true : false} />
                    <label className="slds-checkbox__label" htmlFor="choking_food">
                      <span className="slds-checkbox_faux"></span>
                      <span className="slds-form-element__label"> Food</span>
                    </label>
                  </span>
                </div>}

                {this.state.risk_choking == '2' && this.state.choking_food == '1' && <div className="slds-form-element__control row">
                  <fieldset className="slds-form-element mb-3">
                    <div className="col-md-6"><label className="slds-form-element__legend slds-form-element__label">Description</label>
                      <div className="slds-form-element__control">
                        <div className="SLDS_date_picker_width">
                          <Textarea
                            type="text"
                            className="slds-input"
                            name="choking_food_desc"
                            placeholder="Enter the details of “Food”"
                            value={this.state.choking_food_desc}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>}

                {this.state.risk_choking == '2' && <div className="slds-form-element__control row">
                  <span className="slds-checkbox slds-float_left col col-sm-6">
                    <input type="checkbox" name="choking_fluids" id="choking_fluids" disabled checked={(this.state.choking_fluids && this.state.choking_fluids == '1') ? true : false} />
                    <label className="slds-checkbox__label" htmlFor="choking_fluids">
                      <span className="slds-checkbox_faux"></span>
                      <span className="slds-form-element__label"> Fluids</span>
                    </label>
                  </span>
                </div>}

                <div className="slds-form-element__control row">
                  {this.state.risk_choking == 2 && this.state.choking_fluids == '1' && <fieldset className="slds-form-element mb-3">
                    <div className="col-md-6"><label className="slds-form-element__legend slds-form-element__label">Description</label>
                      <div className="slds-form-element__control">
                        <div className="SLDS_date_picker_width">
                          <Textarea
                            type="text"
                            className="slds-input"
                            name="choking_fluids_desc"
                            placeholder="Enter the details of “Fluids”"
                            value={this.state.choking_fluids_desc}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>}
                </div>
                <div className="slds-form-element__control row">
                  <div className="col-lg-6 col-sm-6 ">
                    <div className="slds-form-element">
                      <legend className="slds-form-element__legend slds-form-element__label"> Food Preferences</legend>
                      <div className="slds-form-element__control">
                        <div className="SLDS_date_picker_width">


                          {this.state.selected_food_preferences && this.state.selected_food_preferences.map((data, i) =>
                            <label className="slds-radio__label" htmlFor="inout_chair_1">
                              <span className="slds-radio_faux"></span>
                              <span className="slds-form-element__label">{i != this.state.selected_food_preferences.length - 1 ? data.label + ', ' : data.label}</span>
                            </label>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="slds-form-element__control row">
                  {this.state.show_food_desc && <fieldset className="slds-form-element mb-3">
                    <div className="col-md-6"><label className="slds-form-element__legend slds-form-element__label">Description</label>
                      <div className="slds-form-element__control">
                        <div className="SLDS_date_picker_width">
                          <Textarea
                            type="text"
                            className="slds-input"
                            name="food_preferences_desc"
                            placeholder="Enter the details of “Fluids”"
                            value={this.state.food_preferences_desc}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>}
                </div>

                <fieldset className="slds-form-element">
                  <div className="slds-form-element__control row">

                    <legend className="slds-form-element__legend slds-form-element__label">
                      Percutaneous Endoscopic Gastromy (PEG) Meal Assistance</legend>
                    <div className="slds-form-element__control">
                      <span className="slds-radio slds-float_left">
                        <input type="radio" value="1" id="peg_assistance_plan_no" required name="peg_assistance_plan" disabled checked={(this.state.peg_assistance_plan && this.state.peg_assistance_plan == 1) ? true : false} />
                        <label className="slds-radio__label" htmlFor="peg_assistance_plan_no">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">No</span>
                        </label>
                      </span>
                      <span className="slds-radio slds-float_left">
                        <input type="radio" value="2" id="peg_assistance_plan_yes" name="peg_assistance_plan" disabled checked={(this.state.peg_assistance_plan && this.state.peg_assistance_plan == 2) ? true : false} />
                        <label className="slds-radio__label" htmlFor="peg_assistance_plan_yes">
                          <span className="slds-radio_faux"></span>
                          <span className="slds-form-element__label">Yes</span>
                        </label>
                      </span>
                    </div>
                  </div>
                </fieldset>

                <fieldset className="slds-form-element">
                  <legend className="slds-form-element__legend slds-form-element__label">
                    Percutaneous Endoscopic Jejunostomy (PEJ) Meal Assistance</legend>
                  <div className="slds-form-element__control">
                    <span className="slds-radio slds-float_left">
                      <input type="radio" value="1" id="pej_assistance_plan_no" required name="pej_assistance_plan" disabled checked={(this.state.pej_assistance_plan && this.state.pej_assistance_plan == 1) ? true : false} />
                      <label className="slds-radio__label" htmlFor="pej_assistance_plan_no">
                        <span className="slds-radio_faux"></span>
                        <span className="slds-form-element__label">No</span>
                      </label>
                    </span>
                    <span className="slds-radio slds-float_left">
                      <input type="radio" value="2" id="pej_assistance_plan_yes" name="pej_assistance_plan" disabled checked={(this.state.pej_assistance_plan && this.state.pej_assistance_plan == 2) ? true : false} />
                      <label className="slds-radio__label" htmlFor="pej_assistance_plan_yes">
                        <span className="slds-radio_faux"></span>
                        <span className="slds-form-element__label">Yes</span>
                      </label>
                    </span>
                  </div>
                </fieldset>

              </div>
            </form>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

export default NutritionalSupportCard