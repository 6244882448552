import React, { Component } from 'react';
import 'react-select-plus/dist/react-select-plus.css';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { postData, reFreashReactTable, css, AjaxConfirm, toastMessageShow, getApplicantId, getMemberId } from 'service/common.js';
import { defaultSpaceInTable } from 'service/custom_value_data.js';
import { ROUTER_PATH } from 'config.js';
import PropTypes from 'prop-types';
import moment from "moment";
import jQuery from 'jquery';
import SLDSReactTable from '../../salesforce/lightning/SLDSReactTable'
import '../../scss/components/admin/crm/pages/contact/ListContact.scss'
import { showArchiveMemberUnavailabilityModal, openAddEditMemberUnavailabilityModal } from '../MemberCommon';
import { 
    IconSettings, 
    PageHeader, 
    PageHeaderControl, 
    Icon, 
    ButtonGroup, 
    Button, 
    Dropdown, 
    DropdownTrigger,
    Input,
    Card,
    InputIcon
} from '@salesforce/design-system-react';
import MemberUnavailability from './MemberUnavailability';

/**
 * RequestData get the list of member unavailability
 */
const requestData = (pageSize, page, sorted, filtered) => {
    return new Promise((resolve, reject) => {
        // request json
        var Request = { applicant_id: getApplicantId(), member_id: getMemberId(), pageSize: pageSize, page: page, sorted: sorted, filtered: filtered };
        postData('recruitment/Recruitment_member/get_member_unavailability_list', Request).then((result) => {
            if (result.status) {
                let filteredData = result.data;
                const res = {
                    rows: filteredData,
                    pages: (result.count)
                };
                resolve(res);
            } else {
                const res = {
                    rows: [],
                    pages: 0
                };
                resolve(res);
            }
        });
    });
};

/**
 * Class: ListMemberUnavailability
 */
class ListMemberUnavailability extends Component {
    
    /**
     * Set visible columns of the table
     */
    static defaultProps = {
        displayed_columns: {
            'unavailability_type': true,
            'start_date': true,
            'end_date': true,
            'actions': true
        }
    }

    /**
     * setting the initial prop values
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        
        var displayed_columns = Object.keys(props.displayed_columns).filter(k => !!props.displayed_columns[k]);
        
        // Initialize state
        this.state = {
            applicant_id: getApplicantId(),
            member_id: getMemberId(),
            member_unavailability: [],
            member_unavailability_id: '',
            filter_status: 'all',
            default_displayed_columns: displayed_columns,
            displayed_columns: [...displayed_columns],
            openCreateModal: false
        }
        // Initilize react table
        this.reactTable = React.createRef();
        this.rootRef = React.createRef()
    }
   
    /**
     * Call the requestData
     * @param {temp} state 
     */
    fetchData = (state, instance) => {
        this.setState({ loading: true });
        requestData(
            state.pageSize,
            state.page,
            state.sorted,
            state.filtered
        ).then(res => {
            this.setState({
                member_unavailability: res.rows,
                pages: res.pages,
                loading: false
            });
        });
    }

    /**
     * Get the list based on Search value
     * @param {object} e 
     */
    submitSearch = (e) => {
        e.preventDefault();
        this.setTableParams();
    }

    /**
     * Set the data for fetching the list
     */
    setTableParams = () => {
        var search_re = { search: this.state.search, filter_status: this.state.filter_status };
        this.setState({ filtered: search_re });
        if(this.props.isMobile) {
            this.fetchData({pageSize: 9999, page: 0, sorted: '', filtered: search_re});
        }
    }

    /**
     * Open create member unavailability modal
     */
    showModal(member_unavailability_id) {
        this.setState({ openCreateModal: true, member_unavailability_id: member_unavailability_id });
    }

    /**
     * Open archive member unavailability modal
     */
    showArchiveModal(member_unavailability_id) {
        showArchiveMemberUnavailabilityModal(member_unavailability_id, this.setTableParams);
    }

    /**
     * fetching the member details by the applicant id
     */
    get_applicant_member_details_by_id = () => {
        if (this.state.applicant_id || this.state.member_id) {
            postData('recruitment/Recruitment_member/get_applicant_member_details_by_id', { applicant_id: this.state.applicant_id, member_id: this.state.member_id }).then((result) => {
                if (result.status) {
                    var id = result.data.id;
                    this.setState(result.data);
                }
            });
        }
    }

    /*
     * method runs after the component output has been rendered to the DOM
     */
    componentDidMount() {       
        jQuery(this.rootRef.current).parent('.col-lg-11').removeClass('col-lg-11').addClass('col-lg-12')
        this.get_applicant_member_details_by_id();
        if(this.props.isMobile) {
            this.fetchData({pageSize: 9999, page: 0, sorted: '', filtered: ''});
        }
    }

    componentWillUnmount() {
        jQuery(this.rootRef.current).parent('.col-lg-12').removeClass('col-lg-12').addClass('col-lg-11')
    }

    /**
     * Render page header actions
     */
    handleOnRenderActions = () => {
        return (
            <React.Fragment>
                <PageHeaderControl>
                    <Button label="New" onClick={e => this.showModal()} />
                </PageHeaderControl>
            </React.Fragment> 
        )
    }

    /**
     * Close the modal when user save the member unavailability and refresh the table
     */
    closeAddEditMemberUnavailabilityModal = (status) => {
        this.setState({openCreateModal: false});

        if(status){
            this.setTableParams();
        }
    }

    /**
     * Render search input form
     */
    renderSearchForm() {
        return (
            <form 
                autoComplete="off" 
                onSubmit={(e) => this.submitSearch(e)} 
                method="post" 
                style={{ display: 'block' }}
            >
                <Input
                    iconLeft={
                        <InputIcon
                            assistiveText={{
                                icon: 'Search',
                            }}
                            name="search"
                            category="utility"
                        />
                    }
                    onChange={(e) => this.setState({ search: e.target.value })}
                    id="Member-unavailability-search-1"
                    placeholder="Search Member Unavailability"
                />
            </form>
        )
    }

    /**
     * Handle the selected columns visible or not 
     */
    handleOnSelectColumnSelectorItem = option => {
        const value = option.value

        let cols = [...this.state.displayed_columns]
        if (cols.indexOf(value) >= 0) {
            cols = cols.filter(col => col !== value)
        } else {
            cols = [...this.state.displayed_columns, value]
        }

        this.setState({ displayed_columns: cols })
    }

    /**
     * Render table column dropdown
     * @param {object} param
     * @param {(import('react-table').Column & { _label: string })[]} [param.columns]
     */
    renderColumnSelector({ columns = [] }) {
        columns = columns.filter(col => (col.accessor != "actions"));
        const mapColumnsToOptions = columns.map(col => {
            return ({ 
                value: 'id' in col ? col.id : col.accessor,
                label: col._label,
            })
        })

        return (
            <Dropdown
                align="right"
                checkmark
                multiple
                assistiveText={{ icon: 'More' }}
                iconCategory="utility"
                iconName="settings"
                iconVariant="more"
                options={mapColumnsToOptions}
                value={this.state.default_displayed_columns}
                onSelect={this.handleOnSelectColumnSelectorItem}
            >
                <DropdownTrigger>
                    <Button
                        title={`Show/hide columns`}
                        assistiveText={{ icon: 'Show/hide columns' }}
                        iconCategory="utility"
                        iconName="table"
                        iconVariant="more"
                        variant="icon"
                    />
                </DropdownTrigger>
            </Dropdown>
        )
    }

    /**
     * Render page header
     * @param {object} param
     * @param {import('react-table').Column[]} [param.columns]
     */
    handleOnRenderControls = ({ columns = [] }) => () => {
        return (
            <React.Fragment>
                <PageHeaderControl>
                    { this.renderSearchForm() }
                </PageHeaderControl>
                {(this.props.isMobile) ? '' :
                <PageHeaderControl>
                    { this.renderColumnSelector({ columns }) }
                </PageHeaderControl>}
            </React.Fragment>
        )
    }

    /**
     * Table columns
     * @returns {(import('react-table').Column & { _label: string })[]}
     */
    determineColumns() {
        return [
            {
                _label: 'Start Date',
                accessor: "start_date",
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate">{props.value != "" && props.value != "0000-00-00 00:00:00" ? moment(props.value).format("DD/MM/YYYY HH:mm") : "N/A"}</span>
            },
            {
                _label: 'End Date',
                accessor: "end_date",
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="vcenter slds-truncate">{props.value != "" && props.value != "0000-00-00 00:00:00" ? moment(props.value).format("DD/MM/YYYY HH:mm") : "N/A"}</span>
            },
            {
                _label: 'Type',
                accessor: "unavailability_type",
                Header: ({ data, column }) => <div className="slds-truncate">{column._label}</div>,
                Cell: props => <span className="slds-truncate">{props.value != null ? defaultSpaceInTable(props.value) : "N/A"}</span>
            },
            {
                _label: 'Action',
                accessor: "actions",
                Header: props => <div style={{width:'1.5rem'}}></div>,
                width:'1.5rem',
                Cell: props => <Dropdown
                assistiveText={{ icon: 'More Options' }}
                iconCategory="utility"
                iconName="down"
                align="right"
                iconSize="x-small"
                iconVariant="border-filled"
                onSelect={(e) => {
                    if(e.value == 1){ //edit
                        this.showModal(props.original.id)
                    }
                    else { // delete
                        this.showArchiveModal(props.original.id)
                    }
                }}
                className={'slds-more-action-dropdown'}
                options={[
                    { label: 'Edit', value: '1' },
                    { label: 'Delete', value: '2' },
                ]}
            />
            
            }
        ];
    }

    /**
     * render header part
     */
    renderHeader() {
        const columns = this.determineColumns();
        const trail = [
            <Link to={ROUTER_PATH + `myshifts`} className="reset" style={{ color: '#0070d2' }}>
                {'My Shifts'}
            </Link>,
            <a href={ROUTER_PATH + `mydetails`} className="reset" style={{ color: '#0070d2' }} id="shift-details">{this.state.fullname}</a>,

        ];

        if(this.props.isMobile) {
            return (
                <PageHeader
                icon={
                        <Link to={ROUTER_PATH + `myshifts`} className="back_link" style={{ color: '#0070d2' }}>
                        <Button
                            assistiveText={{ icon: 'Icon Bare Small' }}
                            iconCategory="utility"
                            iconName="chevronleft"
                            iconSize="large"
                            iconVariant="bare"
                            onClick={() => {
                                console.log('Icon Bare Clicked');
                            }}
                            variant="icon"
                        />
                    </Link>
                }
                className="slds-header-document-mob-view"
                onRenderActions={this.handleOnRenderActions}
                onRenderControls={this.handleOnRenderControls({ columns })}
                trail={[<span> </span>]}
                title="Unavailability"
                label={<span />}
                truncate
                variant="object-home"
            />
            )
        }
        else {
            return (
                <React.Fragment>
                <PageHeader
                    trail={trail}
                    onRenderActions={this.handleOnRenderActions}
                    onRenderControls={this.handleOnRenderControls({ columns })}
                    title="Unavailability"
                    label=" "
                    truncate
                    variant="related-list"
                />
                </React.Fragment>
            )
        }
    }

    /**
     * Rendering the mobile listing part.
     */
    renderMobileList() {

        if(this.state.loading) {
            return <React.Fragment></React.Fragment>
        }
        
        if (this.state.member_unavailability.length == 0 && this.props.isMobile) {
            return <div class="slds-notify slds-notify--alert slds-theme--info slds-theme--alert-texture slds-notify--mobile slds-align-left" role="alert">No shifts found!</div>
        }

        return (
            <React.Fragment>
                <div className="mt-2">
                <Card
                    hasNoHeader={true}
                    className="slds-card-bor"
                    >
                    <MemberUnavailability member_unavailability={this.state.member_unavailability} member_unavailability_count={this.state.member_unavailability_count} get_member_unavailability={this.setTableParams} type="withoutcard"></MemberUnavailability>
                </Card>
                </div>
            </React.Fragment>
        )
    }
    
    /**
     * Render the display content
     */
    render() {
        // table cloumns
        const columns = this.determineColumns();
        const displayedColumns = columns.filter(col => this.state.displayed_columns.indexOf(col.accessor || col.id) >= 0)
        const styles = css({
            root: {
                fontFamily: "Salesforce Sans, Arial, Helvetica, sans-serif",
                marginRight: -15,
            }
        })
        // return
        return (
            <React.Fragment>
                <div className="slds col-lg-12 custom_page_header" style={styles.root} ref={this.rootRef}>
                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                        {this.renderHeader()}
                        {(this.props.isMobile) ? this.renderMobileList() : <SLDSReactTable
                            PaginationComponent={() => false}
                            ref={this.reactTable}
                            manual="true"
                            pages={this.state.pages}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            defaultFiltered={{ filter_status: 'all' }}
                            columns={displayedColumns}
                            data={this.state.member_unavailability}
                            defaultPageSize={9999}
                            minRows={1}
                            getTableProps={() => ({ className: 'slds-table slds-table_cell-buffer slds-table_bordered slds-table_striped slds-tbl-roles' })}
                            onPageSizeChange={this.onPageSizeChange}
                            noDataText="No Record Found"
                            collapseOnDataChange={true}
                            resizable={true}                                     
                        />}
                    </IconSettings>
                </div>
                {openAddEditMemberUnavailabilityModal(this.state.member_unavailability_id, this.state.member_id, this.state.openCreateModal, this.closeAddEditMemberUnavailabilityModal)}
            </React.Fragment>
        )
    }
}
// Defalut Prop
ReactTable.PropTypes = {
    defaultFiltered: PropTypes.object
}

export default ListMemberUnavailability;
